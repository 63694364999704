'use client';
import theme from '@/utils/theme';
import Link from 'next/link';
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { pages } from '@/constants';
import { Badge, Container, Stack } from '@mui/material';
import userContained from '/public/assets/svg/user-outlined.svg';
import bellOutlinedIcon from '/public/assets/svg/bell-oulined.svg';
import Image from 'next/image';
import chevronIcon from '/public/assets/svg/chevron-down.svg';
import logo from '/public/assets/svg/logo.svg';

interface Props {
  readonly window?: () => Window;
  admission?: boolean;
}

export default function DrawerAppBar(props: Props) {
  const { window, admission } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = React.useCallback(() => {
    setMobileOpen((prevState) => !prevState);
  }, []);

  const drawer = React.useMemo(
    () => (
      <Box
        onClick={handleDrawerToggle}
        sx={{ textAlign: 'center', paddingY: 1 }}
        data-test-id="drawer"
      >
        <Image src={logo} height={44} width={235} alt="logo" />
        <Divider />
        <List>
          {pages.map((item) => (
            <ListItem key={item} disablePadding>
              <ListItemButton
                sx={{ textAlign: 'center' }}
                data-test-id={`drawer-item-${item}`}
              >
                <ListItemText primary={item} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    ),
    [handleDrawerToggle]
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <AppBar component="nav" sx={classes.root} data-test-id="app-bar">
        <Container disableGutters>
          <Toolbar sx={classes.justifyBetween}>
            <Link href="/" style={{ textDecoration: 'none' }} passHref>
              <Image src={logo} height={44} width={235} alt="logo" />
            </Link>

            <Box display={'flex'} component="nav">
              <Box
                sx={{ display: { xs: 'none', md: 'block' } }}
                data-test-id="desktop-nav"
              >
                <Box display="flex">
                  {pages.map((item) => (
                    <Link href={`/${item.toLowerCase()}`} key={item} passHref>
                      <Button
                        sx={classes.navItem}
                        data-test-id={`nav-item-${item}`}
                      >
                        {item}
                      </Button>
                    </Link>
                  ))}
                  {admission && (
                    <>
                      <IconButton
                        size="large"
                        aria-label="show 17 new notifications"
                        color="inherit"
                      >
                        <Badge badgeContent={1} color="error">
                          <Image
                            width={24}
                            height={24}
                            src={bellOutlinedIcon.src}
                            alt="bell-icon"
                          />
                        </Badge>
                      </IconButton>
                      <Box display="flex" ml={4} alignItems="center">
                        <Box
                          sx={{
                            backgroundColor: '#E8ECF4',
                            display: 'inline-block',
                            p: 1.5,
                            borderRadius: '50%'
                          }}
                        >
                          <Image
                            src={userContained}
                            alt="avatar icon"
                            width={32}
                            height={32}
                            style={{ padding: 0, margin: 0, display: 'block' }}
                          />
                        </Box>
                        <Stack ml={1.5}>
                          <Box display="flex" alignItems="center">
                            <Typography color={'primary'} variant="body1">
                              Usman Khan
                            </Typography>
                            <Image alt="down icon" src={chevronIcon} />
                          </Box>
                          <Typography fontSize={10} variant="body2">
                            @usmankhan18
                          </Typography>
                        </Stack>
                      </Box>
                    </>
                  )}
                </Box>
              </Box>

              <Link href="/login" style={{ textDecoration: 'none' }} passHref>
                <Button
                  variant={admission ? 'contained' : 'outlined'}
                  sx={{
                    ...classes.getStartedBtn,
                    display: {
                      xs: admission ? 'none' : 'block',
                      sm: 'block',
                      md: admission ? 'none' : 'block'
                    }
                  }}
                  data-test-id="get-started-btn"
                >
                  Get Started
                </Button>
              </Link>

              <IconButton
                color="primary"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ ml: 2, display: { md: 'none' } }}
                data-test-id="menu-icon"
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true
          }}
          sx={classes.drawer}
          data-test-id="mobile-drawer"
        >
          {drawer}
        </Drawer>
      </nav>
    </>
  );
}

const classes = {
  root: {
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    py: '10px',
    position: 'relative'
  },
  logoText: {
    flexGrow: { xs: 1, md: 0 },
    fontWeight: 800,
    fontSize: {
      xs: '16px',
      sm: '24px'
    },
    color: theme.palette.text.primary,
    textDecoration: 'none'
  },
  justifyBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  menuIcon: {
    display: { md: 'none' },
    color: {
      xs: theme.palette.text.primary,
      md: theme.palette.primary.main
    }
  },
  drawer: {
    display: { xs: 'block', md: 'none' },
    '& .MuiDrawer-paper': {
      boxSizing: 'border-box',
      maxWidth: 320,
      width: '100%'
    }
  },
  getStartedBtn: {
    ml: 1,
    py: {
      xs: '10px'
    }
  },
  navItem: {
    color: theme.palette.text.primary,
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: 'transparent'
    }
  }
};
